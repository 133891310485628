html, body
{
    margin: 0 !important;
    padding: 0 !important;
	overflow: hidden;

	/* font-family: Arial, Helvetica, sans-serif; */
	font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
	background-color: rgb(39, 39, 39);
}



.page-header
{
	display: inline-block;
	width: 100%;
	/* margin-top: 10px; */
	/* height: 200px; */
}

.megatitle
{
	font-size: 40px;
	padding-left: 20px;
	color: white;
	user-select: none;
}

.login-area
{
	margin-top: 10px;
	margin-right: 20px;
	float: right;
}

.header-text-button
{
	background-color: rgb(165, 107, 0);
	color: white;
	font-size: 20px;
	/* padding-right: 20px; */
}

.social-links
{
	margin-left: auto;
	margin-right: 20px;
	display: inline-block;
}

button
{
	transition: 0.15s;
	border-radius: 3px;
}
button:hover { filter: brightness(120%); }
button:active { filter: brightness(150%); }


.syllobutton
{
	background-color: rgb(41, 41, 41);
	border-color: gray;
	display: block;
	margin: 2px;
	min-width: 150px;
	color: white;
}

.sylloform
{
	background-color: rgb(255, 164, 89);
	width: 1px;
	min-width: 500px;
	min-height: 600px;

	margin: auto;
	border-radius: 10px;
	border-width: 2px;
	border-color: white;

	border-style: solid;
	padding: 20px;
	font-size: larger;

}

button:disabled
{
	opacity: 0.4;
	filter: none;
}

.image-link-button
{
	display: block;
	height: 32px;
	width: 32px;
	background: none;
	border: none;
	background-repeat: no-repeat;
	background-position: center;
	background-size: contain;
	cursor: pointer;
}



.nav-text
{
	color: white;
	background: none;
}

.syllocanvas
{
	cursor: all-scroll;
	background-color: rgb(58, 58, 58);
	border: 5px solid black;
}

.reconnect-div
{
	position: absolute;
	display: flex;
	font-size: 100px;
	left: 0px;
	right: 0px;
	top: 0px;
	bottom: 0px;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.75);
	color: white;
	user-select: none;
	z-index: 99999999999999999;
}

.contextmenu
{
	position: absolute;
	background-color: rgb(34, 34, 34);
	
	/* min-height: 100px; */
	min-width: 120px;
	border: 2px;
	border-radius: 3px;
}

.contextmenuButton
{
	display: block;
	width: 100%;
	background: none;
	color: white;
	border-color: gray;
}

.contextmenuButton:hover
{
	background-color: white;
	color: black;
	font-weight: bold;
}

.contextmenuButton:disabled
{
	background-color: inherit;
	color: white;
	font-weight: inherit;
}

.popup
{
	background-color: rgb(67, 67, 67);
	height: auto;
	width: auto;
	border: 1px solid black;
	position: absolute;
	top: 50px;
	left: 50px;
	/* transition: 0.15s; */
	border-radius: 5px;
	/* min-width: 280px; */
	/* min-height: 100px; */
	-webkit-transition: background-color 0.15s linear;
    -ms-transition: background-color 0.15s linear;
    transition: background-color 0.15s linear;
	
	
	color: white;
	
	opacity: 1;
	
	filter: drop-shadow(10px 10px 5px rgba(0, 0, 0, 0.25));
}

.message
{
	margin: 5px;
}

.popupbutton
{
	margin-top: 10px;
	display: inline-flex;
	bottom: 0;
}